import React from 'react';
import { PageProps } from 'gatsby';
import styled from 'styled-components';

const Main = styled.main`
  background: black;
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  color: white;

  iframe {
    min-width: 100vw;
    min-height: 100vh;
  }
`;

const Home: React.FC<PageProps> = () => (
  <Main>
    <iframe
      title="Money money money"
      src="https://www.youtube.com/embed/ETxmCCsMoD0"
    />
  </Main>
);

export default Home;
